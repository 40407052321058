import { events, emitEvent } from '../utilities/custom-events';

const start = Date.now();

/**
 * Spy sections and add class when in view
 * @param {string} sectionHandle
 * @param {string} inViewClass
 */
export function sectionSpy(sectionHandle = '.spy', inViewClass = 'in-view') {
  const sections = document.querySelectorAll(sectionHandle);

  // Event handler functions
  function handleScroll(entries, observer) {
    if (entries.length === 0) {
      observer.disconnect();
    }

    Array.from(entries)
      .filter(entry => (
        entry.target.classList.contains(inViewClass)
        || entry.intersectionRatio > (entry.target.getAttribute('data-threshold') || 0.5)
      ))
      .filter(entry => {
        if (Date.now() - start > (entry.target.getAttribute('data-delay') || 0)) {
          return true;
        }

        // Check back
        setTimeout(handleScroll.bind(this, [entry], observer), 100);

        return false;
      })
      .forEach(entry => {
        entry.target.classList.add(inViewClass);
        observer.unobserve(entry.target);
      });
  }

  // Initialize
  const observer = new IntersectionObserver(handleScroll, {
    // Call at multiple thresholds to allow for customization via data attribute
    threshold: [0, 0.1, 0.25, 0.5, 0.75, 1],
  });
  Array.from(sections).forEach(section => { observer.observe(section); });
}

/**
 * fixSpy - Add fixed class to element when it reaches top of document
 * @param {node} el
 * @param {string} fixedClass
 * @param {bool} controlHeader
 * @return {void}
 */
export function fixSpy(el, fixedClass = 'is-fixed', controlHeader) {
  if (el.getBoundingClientRect().top < 0) {
    el.classList.add(fixedClass);
    if (controlHeader) emitEvent(events.hideHeader);
  } else {
    el.classList.remove(fixedClass);
    if (controlHeader) emitEvent(events.showHeader);
  }
}
/**
 * anchorSpy - Spy anchor section and add active class to anchor link when section in view
 * @param {[]node} anchors
 * @param {[]node} sections
 * @param {float} threshold - helps define when section is considered "in view"
 * @param {string} activeClass
 * @callback {function(node)} cb
 * @return {void}
 */
export function anchorSpy(anchors, sections, threshold, activeClass = 'is-active', cb = null) {
  const activeSection = Array.from(sections).findIndex(section => {
    const rect = section.getBoundingClientRect();
    return rect.top < threshold && rect.bottom > threshold;
  });
  Array.from(anchors).forEach((anchor, i) => {
    if (i === activeSection) {
      anchor.classList.add(activeClass);
    } else {
      anchor.classList.remove(activeClass);
    }
  });
  if (cb && activeSection !== -1) cb(anchors[activeSection]);
}

