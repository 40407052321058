import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
        fixedClass,
        state,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);

        // Event handler functions
        function handleScroll() {
            if (window.pageYOffset > el.offsetHeight) {
                el.classList.add(fixedClass);
            } else {
                el.classList.remove(fixedClass);
            }
        }
        const handleResize = debounce(() => {
            state.headerHeight = el.offsetHeight;
        });

        // Add event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Initialize
        state.headerHeight = el.offsetHeight;
    }
}
