import { fixSpy } from '../utilities/spy';

export default class {
    constructor({
        id,
        inputHandle,
        fixedClass,
        controlHeader = false,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const dropdowns = form.querySelectorAll('input[type="checkbox"]');
        const inputs = el.querySelectorAll(inputHandle);

        // Event handler functions
        function handleScroll() {
            fixSpy(el, fixedClass, controlHeader);
        }
        const handleDropdowns = Array.from(dropdowns).map((dropdown, i) => () => {
        // Close all other dropdowns when one opens
        Array.from(dropdowns)
            .filter((dd, ii) => (ii !== i))
            .forEach(dd => { dd.checked = false; });
        });
        function handleChange() {
            form.submit();
        }

        // Add event listeners
        window.addEventListener('scroll', handleScroll);
        Array.from(dropdowns).forEach((dropdown, i) => {
            dropdown.addEventListener('change', handleDropdowns[i]);
        });
        Array.from(inputs).forEach(input => {
            input.addEventListener('change', handleChange);
        });
    }
}
