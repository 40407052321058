import {
    hasClass,
    addClass,
    removeClass,
} from "../utilities/dom";

export default class {
    constructor({
        id,
        videoContainerHandle,
        triggerHandle,
        closeHandle,
        activeClass,
    }) {

        // Elements and class variables
        const docBody = document.querySelector("body");
        const el = document.getElementById(id);
        const videoContainer = el.querySelector(videoContainerHandle);
        const modalClose = el.querySelector(closeHandle);
        const triggers = document.querySelectorAll(triggerHandle);
        const lockedClass = "is-locked";

        // Function to open video modal and load in iframe video
        function openVideoModal(triggerVideoID) {
            if (triggerVideoID) {
                docBody.classList.add(lockedClass);
                videoContainer.innerHTML = `<iframe src="https://player.vimeo.com/video/${triggerVideoID}?quality=1080p" title="" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" data-ready="true"></iframe>`; // eslint-disable-line max-len
                if (!hasClass(el, activeClass)) {
                    addClass(el, activeClass);
                }
            }
        }

        // Loop through triggers and add event listeners to send
        // video id to modal function when clicked
        if (triggers) {
            Array.from(triggers).forEach(trig => {
                trig.addEventListener("click", event => {
                    event.preventDefault();
                    openVideoModal(trig.getAttribute("data-video"));
                });
            });
        }

        // Close modal with button
        modalClose.addEventListener("click", event => {
            event.preventDefault();
            docBody.classList.remove(lockedClass);
            if (hasClass(el, activeClass)) {
                removeClass(el, activeClass);
                videoContainer.innerHTML = "";
            }
        });

        // Close modal with escape key
        window.addEventListener("keypress", e => {
            if (e.key === "Escape") {
                if (hasClass(el, activeClass)) {
                    removeClass(el, activeClass);
                    videoContainer.innerHTML = "";
                    docBody.classList.remove(lockedClass);
                }
            }
        });
    }
}
