export default class {
  constructor({
    id,
    activeClass
  }) {
    const el = document.getElementById(id);
    const nav = el.querySelector('nav');
    const tabs = nav.querySelectorAll('button');
    const list = el.querySelector('ul');
    const details = list.querySelectorAll('li');

    const handleTabs = Array.from(tabs).map((t, i) => () => {
      // Accordions on mobile
      if (window.innerWidth <= 768) {
        t.classList.toggle(activeClass);
      }

      // Tabs on desktop
      if (window.innerWidth > 768) {
        tabs.forEach((tt, ii) => { tt.classList.toggle(activeClass, i === ii); });
        details.forEach((d, ii) => { d.classList.toggle(activeClass, i === ii); });
      }
    });

    tabs.forEach((t, i) => {
      t.addEventListener('click', handleTabs[i]);
    });
  }
}
