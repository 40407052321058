import { events, emitEvent } from '../utilities/custom-events';
import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
        toggleInputHandle,
        visibleClass,
        state,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const toggleInput = el.querySelector(toggleInputHandle);

        // Event handler functions
        function handleShowHeader() {
            el.classList.add(visibleClass);
        }
        function handleHideHeader() {
            el.classList.remove(visibleClass);
        }
        const handleResize = debounce(() => {
            state.headerHeight = el.offsetHeight;
        });
        function handleToggle() {
            if (toggleInput.checked) {
                emitEvent(events.lockScroll);
            } else {
                emitEvent(events.unlockScroll);
            }
        }

        // Add event listeners
        window.addEventListener(events.showHeader, handleShowHeader);
        window.addEventListener(events.hideHeader, handleHideHeader);
        window.addEventListener('resize', handleResize);
        if (toggleInput) toggleInput.addEventListener('change', handleToggle);

        // Initialize
        handleShowHeader();
        state.headerHeight = el.offsetHeight;
    }
}
