import { events, emitEvent } from '../utilities/custom-events';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        aspectHandle,
        triggerHandle,
        detailHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const aspects = el.querySelectorAll(aspectHandle);
        const triggers = el.querySelectorAll(triggerHandle);
        const details = el.querySelectorAll(detailHandle);

        // Event handler functions
        function handleCloseSidebar() {
            aspects.forEach(a => { a.classList.remove(activeClass); });
        }

        const handleTriggers = Array.from(triggers).map((t, i) => () => {
            const aspect = aspects[i];
            const markup = details[i].innerHTML;
            const position = t.getAttribute('data-modal-position') || 'left';

            function cb() {
                aspect.classList.add(activeClass);
                emitEvent(events.loadSidebar, { markup, position });
            }

            // Align aspect heading w/ sidebar heading (padding-top: 128px)
            scrollTop(aspect.querySelector('h3'), 128, cb);
        });

        // Add event listeners
        window.addEventListener(events.closeSidebar, handleCloseSidebar);
        triggers.forEach((t, i) => {
            t.addEventListener('click', handleTriggers[i]);
        });
    }
}
