import GoogleMapsLoader from 'google-maps';

export default class {
    constructor({
        id,
        mapHandle,
        lat,
        lng,
    }) {
        const el = document.getElementById(id);
        const mapEl = el.querySelector(mapHandle);
        const gApiKey = 'AIzaSyDYBLeZxVHOecja4Wurp5c-Yp5VwCgaeu0';

        GoogleMapsLoader.KEY = gApiKey;
        GoogleMapsLoader.VERSION = 'weekly';

        // Initialize
        GoogleMapsLoader.load(google => {
            const center = new google.maps.LatLng(lat, lng);

            const map = new google.maps.Map(mapEl, {
                center,
                zoom: 16,
                mapTypeControl: false,
                scrollwheel: false,
                styles: [{ stylers: [{ saturation: -100, lightness: 100 }] }], // Grayscale
            });

            const position = center;
            const marker = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                position,
                icon: '/images/icon-map-active.png',
            });

            // Set marker on map
            marker.setMap(map);
        });
    }
}
