import { load } from 'recaptcha-v3';

const RECAPTCHA_SITE_KEY = '6LdvPBQmAAAAANLFk_s6m0AeB1_TxQDelsso5xZq';

export default class {
  constructor({
                id,
              }) {
    console.log('Form constructor called'); // Debugging statement

    const el = document.getElementById(id);
    const form = el.querySelector('form');

    if (!form) {
      console.log('No form found!');
      return;
    }

    const submit = form.querySelector('[type="submit"]');

    let recaptcha = null;

    function handleSubmit(e) {
      if (recaptcha === null) {
        console.log('reCAPTCHA not loaded'); // Debugging statement
        return;
      }

      e.preventDefault();

      submit.disabled = true;
      submit.value = 'Processing...';

      // Get reCAPTCHA score token
      recaptcha.execute('form').then(token => {
        // Create hidden input in form to pass reCAPTCHA score token to server
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', 'token');
        input.setAttribute('value', token);

        form.appendChild(input);
        form.submit();
      });
    }

    // Add event handlers
    // Load reCAPTCHA object from key
    load(RECAPTCHA_SITE_KEY).then(r => {
      recaptcha = r;

      console.log('reCAPTCHA loaded!');

      form.addEventListener('submit', handleSubmit);
    }).catch(error => {
      console.error('Error loading reCAPTCHA:', error); // Debugging statement
    });
  }
}
