export default class {
    constructor({
        id,
        tabHandle,
        sectionHandle,
        colorHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const tabs = el.querySelectorAll(tabHandle);
        const sections = el.querySelectorAll(sectionHandle);
        const colors = el.querySelectorAll(colorHandle);

        const handleTabs = Array.from(tabs).map((t, i) => () => {
            tabs.forEach((tt, ii) => {
                tt.classList.toggle(activeClass, i === ii);
                sections[ii].classList.toggle(activeClass, i === ii);
            });
        });

        const handleColors = Array.from(colors).map((c) => () => {
            const parentSection = c.closest(sectionHandle);
            const figure = parentSection.querySelector('figure');
            const sectionColors = parentSection.querySelectorAll(colorHandle);
            const imageUrl = c.getAttribute('data-image-url');
            const groupName = c.getAttribute('data-group-name');
            const colorName = c.getAttribute('data-color-name');

            // Swap image and active color
            sectionColors.forEach(sc => { sc.classList.toggle(activeClass, sc === c); });
            figure.innerHTML = `
                <img src="${imageUrl}" alt="${colorName}" />
                <figcaption>
                <span>${groupName}</span>
                <span> - </span>
                <em>${colorName}</em>
                </figcaption>
            `;
        });

        tabs.forEach((t, i) => {
            t.addEventListener('click', handleTabs[i]);
        });
        colors.forEach((c, i) => {
            c.addEventListener('click', handleColors[i]);
        });
    }
}
