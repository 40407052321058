export default class {
    constructor({
        id,
        toggleListHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const toggleHandle = el.querySelector(toggleListHandle);
        const parentNode = el.parentNode;
        let currentActive = [];

        // Event handler functions
        function handleToggle() {
            if (el.classList.contains(activeClass)) {
                el.classList.remove(activeClass);
            } else {
                currentActive = parentNode.querySelectorAll(`.${activeClass}`);
                Array.from(currentActive).forEach(item => { item.classList.remove(activeClass); });
                el.classList.add(activeClass);
            }
        }

        // Add event listeners
        toggleHandle.addEventListener("click", event => {
            event.preventDefault();
            handleToggle();
        });
    }
}
