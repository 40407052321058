// import { events, emitEvent } from '../utilities/custom-events';
import { events, emitEvent } from '../utilities/custom-events';
import { injectMarkup } from '../utilities/components';

export default class {
    constructor({
        id,
        closeHandle,
        contentHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const content = el.querySelector(contentHandle);
        const close = el.querySelector(closeHandle);

        // Event handler functions
        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            emitEvent(events.closeSidebar);
        }
        function handleOpenSidebar(e) {
            const {
                size = 'md',
                position = 'left',
                overlay = false,
            } = e.detail;

            el.setAttribute('data-size', size);
            el.setAttribute('data-position', position);
            el.setAttribute('data-overlay', overlay);
            // emitEvent(events.lockScroll);
            document.body.classList.add('is-locked');
            // Delay active class to let data attributes compute before animating
            setTimeout(() => { el.classList.add(activeClass); }, 300);

            document.addEventListener('keyup', handleKeyup);
        }
        function handleCloseSidebar() {
            // emitEvent(events.unlockScroll);
            document.body.classList.remove('is-locked');
            el.classList.remove(activeClass);

            document.removeEventListener('keyup', handleKeyup);
        }
        function handleLoadSidebar(e) {
            const {
                markup,
                size = 'md',
                position = 'left',
                overlay = false,
                cb = null,
            } = e.detail;

            injectMarkup(content, markup);
            emitEvent(events.openSidebar, { size, position, overlay });

            if (cb) cb(content);
        }
        function handleClick() {
            emitEvent(events.closeSidebar);
        }

        // Add event listeners
        window.addEventListener(events.openSidebar, handleOpenSidebar);
        window.addEventListener(events.closeSidebar, handleCloseSidebar);
        window.addEventListener(events.loadSidebar, handleLoadSidebar);
        close.addEventListener('click', handleClick);
    }
}
