import { spy } from 'ui-utilities';
import './polyfills';

// Components
import AgencyMap from './components/agency-map';
import AnchorNav from './components/anchor-nav';
import Carousel from './components/carousel';
import ConditionalInput from './components/conditional-input';
import DropdownList from './components/dropdown-list';
import Filters from './components/filters';
import Form from './components/form';
import Header from './components/header';
import HeaderNav from './components/header-nav';
import HeroCarousel from './components/hero-carousel';
import ProductAspects from './components/product-aspects';
import ProductDesignOptions from './components/product-design-options';
import ProductFeatures from './components/product-features';
import ProductsCarousel from './components/products-carousel';
import Sidebar from './components/sidebar';
import TabSections from './components/tab-sections';
import VideoModal from './components/video-modal';

// Utilities
import { instantiate } from './utilities/components';

/* eslint-disable quote-props */
const classMap = {
    'agency-map': AgencyMap,
    'anchor-nav': AnchorNav,
    'carousel': Carousel,
    'conditional-input': ConditionalInput,
    'dropdown-list': DropdownList,
    'filters': Filters,
    'form': Form,
    'header': Header,
    'header-nav': HeaderNav,
    'hero-carousel': HeroCarousel,
    'product-aspects': ProductAspects,
    'product-design-options': ProductDesignOptions,
    'product-features': ProductFeatures,
    'products-carousel': ProductsCarousel,
    'sidebar': Sidebar,
    'tab-sections': TabSections,
    'video-modal': VideoModal,
};
/* eslint-enable quote-props */

// Event handler functions
function handleDOMContentLoaded() {
    // Call component constructors
    instantiate(classMap);

    spy.images();
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
