import { fixSpy, anchorSpy } from '../utilities/spy';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        activeClass,
        fixedClass,
    }) {
        const el = document.getElementById(id);
        const toggleInput = el.querySelector('[type="checkbox"]');
        const toggleLabel = el.querySelector(`[for="${toggleInput.id}"]`);
        const links = el.querySelectorAll('nav a');
        const linkSections = Array.from(links)
            .map(link => document.getElementById(`${link.href.split('#')[1]}`))
            .filter(link => link);

        // Event handler functions
        function handleScroll() {
            // Fix nav
            fixSpy(el, fixedClass);

            // Mark active section
            const threshold = window.innerHeight / 3;

            function cb(link) {
                // Set toggle label text
                toggleLabel.innerHTML = `<span>${link.textContent}</span>`;
            }

            anchorSpy(links, linkSections, threshold, activeClass, cb);
        }
        function handleChange() {
            if (toggleInput.checked) scrollTop(toggleLabel);
        }
        const handleClicks = Array.from(links).map(link => () => {
            // Set toggle label text
            toggleLabel.innerHTML = `<span>${link.textContent}</span>`;
            // Close dropdown
            toggleInput.checked = false;
        });

        // Add event listeners
        window.addEventListener('scroll', handleScroll);
        toggleInput.addEventListener('change', handleChange);
        Array.from(links).forEach((link, i) => {
            link.addEventListener('click', handleClicks[i]);
        });

        // Initialize
        handleScroll();
    }
}
