export default class {
    constructor({
        id,
        triggerName,
        triggerValue,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const trigger = document.querySelector(`[name="${triggerName}"]`);
        const targetHandle = el.querySelector('.input__input');

        // @TODO provide for other element types
        function reset() {
            /* eslint-disable default-case */
            switch (targetHandle.nodeName.toLowerCase()) {
                case 'select':
                    targetHandle.selectedIndex = -1;
            }
            /* eslint-enable default-case */
        }

        // Event handler functions
        function handleChange(e) {
            if (e.target.value === triggerValue) {
                el.style.display = 'block';
            } else {
                el.style.display = 'none';

                reset();
            }
        }

        // Add event listeners
        trigger.addEventListener('change', handleChange);
    }
}
